import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Features from '../components/Features'
import Testimonials from '../components/Testimonials'
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";

export const QuitSmokingPageTemplate = ({
  image,
  title,
  heading,
  description,
  intro,
  main,
  testimonials
}) => {
  main.description = remark()
      .use(recommended)
      .use(remarkHtml)
      .processSync(main.description).toString();
  main.bottom = remark()
      .use(recommended)
      .use(remarkHtml)
      .processSync(main.bottom).toString();
  description = remark()
      .use(recommended)
      .use(remarkHtml)
      .processSync(description).toString();
  return (
      <div className="content quit-smoking">
        <div
            className="full-width-image-container margin-top-0"
            style={{
              backgroundImage: `url(${
                  !!image.childImageSharp ? image.childImageSharp.fluid.src : image
              })`,
            }}
        >
          <h2
              className="has-text-weight-bold is-size-1"
              style={{
                marginBottom: '-5em',
                boxShadow: '0.5rem 0 0 #4E9836, -0.5rem 0 0 #4E9836',
                backgroundColor: '#4E9836',
                color: 'white',
                padding: '1rem',
              }}
          >{title}
          </h2>
        </div>
        <section className="section section--gradient" style={{marginTop: "-3rem"}}>
          <div className="container">
            <div className="section">
              <div className="columns">
                <div className="column">
                  <h3 className="has-text-weight-semibold has-text-centered is-size-2">{heading}</h3>
                  <p className="has-text-centered" dangerouslySetInnerHTML={{__html: description}}/>
                </div>
              </div>
              <div className="columns">
                <div className="column is-10 is-offset-1" style={{marginTop: "-2rem"}}>
                  <Features gridItems={intro.blurbs}/>
                  <div className="columns">
                    <div className="column is-6">
                      <h3 className="has-text-weight-semibold is-size-3">
                        {main.heading}
                      </h3>
                      <p dangerouslySetInnerHTML={{__html: main.description}}/>
                    </div>
                    <div className="column is-6">
                      <div className="tile is-vertical">
                        <div className="tile">
                          <div className="tile is-parent is-vertical" style={{marginTop: "-3rem"}}>
                            <div>
                              <PreviewCompatibleImage imageInfo={main} />
                              <p className="has-text-centered" dangerouslySetInnerHTML={{__html: main.bottom}} style={{marginTop: '1rem'}}/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Testimonials testimonials={testimonials}/>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
  );
}

QuitSmokingPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  main: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    bottom: PropTypes.string
  }),
  testimonials: PropTypes.array
}

const QuitSmokingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <QuitSmokingPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        main={frontmatter.main}
        testimonials={frontmatter.testimonials}
        pricing={frontmatter.pricing}
      />
    </Layout>
  )
}

QuitSmokingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default QuitSmokingPage

export const quitSmokingPageQuery = graphql`
  query QuitSmokingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
        }
        main {
          heading
          description
          bottom
          image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }
        testimonials {
          author
          quote
        }
      }
    }
  }
`
